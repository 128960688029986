import { TestResponse } from '@rossum/api-client/hooks';
import { FunctionRuntime } from '@rossum/api-client/hooks';
import { Button, useTheme } from '@rossum/ui/material';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useProcessIntent } from '../../../../../features/extension-interop/hooks/useProcessIntent';
import { useFeatureFlag } from '../../../../../unleash/useFeatureFlag';
import { runtimesConfig } from '../../../config';
import styles from '../style.module.sass';
import { TabNames } from '../types';

type Props = {
  activeTab: TabNames;
  children?: ReactNode;
  runtime: FunctionRuntime;
  value: TestResponse | undefined;
};

const ExtensionsOutput = ({ activeTab, children, runtime, value }: Props) => {
  const theme = useTheme();
  const { processIntent } = useProcessIntent();
  const intl = useIntl();
  const commandsEnabled = useFeatureFlag('ac-5730-fe-exe-interop');

  return (
    <div className={styles.Box}>
      {children}

      <div
        className={clsx(
          styles.OutputValue,
          theme.palette.mode === 'light' && styles.OutputValueLight
        )}
      >
        {value ? (
          <>
            {'intent' in value.response && commandsEnabled ? (
              <Button
                variant="outlined"
                onClick={() => processIntent(value.response)}
                sx={{ m: 1 }}
              >
                {intl.formatMessage({ id: 'features.extensionInterop.view' })}
              </Button>
            ) : null}
            <div className={styles.Preformatted}>
              {activeTab === 'response'
                ? JSON.stringify(value.response, undefined, 2)
                : value.log}
            </div>
          </>
        ) : (
          <div className={styles.Preformatted}>
            <FormattedMessage
              id={`components.editor.outputBox.empty.${activeTab}.${runtimesConfig[runtime].type}`}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ExtensionsOutput;
