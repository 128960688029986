import { Card, CardContent, CardMedia, Typography } from '@rossum/ui/material';

export const ImageControl = ({
  uischema: { text, src },
}: {
  uischema: {
    text: string;
    src: string;
  };
}) => (
  <Card sx={{ maxWidth: 245 }}>
    <CardMedia component="img" alt={text} image={src} />

    <CardContent>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {text}
      </Typography>
    </CardContent>
  </Card>
);
