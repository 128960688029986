import './overrideDefaultStyle.css';
import {
  Message as MessageUI,
  Props as MessageProps,
} from '@rossum/rossum-ui/Message';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { defaultLocales } from '../../i18n';
import { MessageId } from '../../redux/modules/messages/actions';
import { State } from '../../types/state';

type DispatchProps = {
  onConfirm?: () => void;
};

type OwnProps = {
  buttonAction?: (..._args: Array<unknown>) => AnyAction;
  buttonFunction?: (..._args: Array<unknown>) => void;
  values?: Record<string, React.ReactNode>;
};

type Props = Omit<MessageProps, 'id'> &
  DispatchProps &
  OwnProps & { id: MessageId };

const hasKeyInTranslations = <K extends string>(
  id: string,
  trailingKey: K
): id is MessageId<K> => {
  return `containers.message.${id}.${trailingKey}` in defaultLocales;
};

const Message = ({ values, ...props }: Props) => (
  <MessageUI
    {...props}
    controlled
    title={
      <FormattedMessage
        id={`containers.message.${props.id}.title`}
        values={values}
        defaultMessage={props.id}
      />
    }
    description={
      hasKeyInTranslations(props.id, 'text') ? (
        <FormattedMessage
          id={`containers.message.${props.id}.text`}
          values={values}
          defaultMessage={props.id}
        />
      ) : undefined
    }
    buttonLabel={
      hasKeyInTranslations(props.id, 'button') ? (
        <FormattedMessage id={`containers.message.${props.id}.button`} />
      ) : undefined
    }
  />
);

const mapDispatchToProps = (
  dispatch: Dispatch,
  { buttonAction, buttonFunction }: OwnProps
) => ({
  onConfirm:
    buttonAction || buttonFunction
      ? (...args: Array<unknown>) => {
          if (buttonAction) dispatch(buttonAction(...args));
          if (buttonFunction) buttonFunction(...args);
        }
      : undefined,
});

export default connect<unknown, DispatchProps, OwnProps, State>(
  null,
  mapDispatchToProps
)(Message);
