import { UserTableConfig } from '@rossum/api-client/shared';
import { ThemeType } from '@rossum/rossum-ui/theme';
import { z } from 'zod';
import { defaultWorkspaceSorting } from '../constants/values';
import {
  DeprecatedSurveySteps,
  SurveyQuestionKeys,
  SurveySteps,
} from '../containers/TrialOnboarding/surveyData';
import { PASSWORD, SSO } from '../redux/modules/users/values';
import { Url } from './basic';
import { GroupRole } from './group';
import { UserProductToursSummary } from './productTour';
import { UiFieldType } from './schema';

// deprecated - keeping just in case for now
type Hints = {
  firstAnnotation?: boolean;
  firstDatapoint?: boolean;
  firstDownload?: boolean;
  firstExport?: boolean;
  firstUpload?: boolean;
  hubspotForm?: boolean;
  tourSkipped?: boolean;
  visitExported?: boolean;
};

export const sortingValues = [
  defaultWorkspaceSorting,
  'alphabeticalDesc',
  'newest',
  'oldest',
  'toReview',
] as const;

export type SortingValue = (typeof sortingValues)[number];

type DataCaptureSurveyData = {
  viewCount: number | null;
  skipCount: number | null;
  answer: number | null;
  annotationIds: number[] | null;
  trigger: string | null;
  lastModifiedAt: string | null;
};

export type FooterLayout = 'table-per-page' | 'one-table';
export type SortFooterColumns = 'automatically' | 'queue-settings';

type SentInvitation = {
  email: string;
  jobPosition: string;
  dateJoined: string | undefined;
  dateOfInvitation: string;
};

const formulaPreviewWidths = z.record(z.number());

export const formulaPreviewSchema = z.object({
  widths: formulaPreviewWidths,
});

type FormulaPreview = z.infer<typeof formulaPreviewSchema>;

export type UiSettings = {
  anyDatapointCorrected?: boolean;
  dashboard: {
    workspacesSorting?: SortingValue;
    hiddenHints?: string[];
  };
  documentsExported?: number;
  hideExtensionsIntroBanner?: boolean;
  hideDagIntroBanner?: boolean;
  hideFirstUploadWarning?: boolean;
  hideWarningEditableFields?: Partial<
    Record<Extract<UiFieldType, 'captured' | 'data'>, boolean>
  >;
  hints?: Hints;
  isWizardSubmitted?: boolean;
  locale?: string;
  onboardingAcknowledged?: boolean;
  theme?: ThemeType;
  productTours?: UserProductToursSummary;
  onboardingSurvey?: {
    activeStep: SurveyQuestionKeys | DeprecatedSurveySteps;
    stepsData: Partial<SurveySteps>;
  };
  feedbackFormsSubmitted?: Record<string, boolean>;
  dataCaptureSurveyData?: Partial<DataCaptureSurveyData>;
  footerLayout?: FooterLayout;
  sortFooterColumns?: SortFooterColumns;
  auroraTourCompleted?: boolean;
  auroraPromoCompleted?: boolean;
  complexLineItems?: boolean;
  sentInvitations?: Array<SentInvitation>;
  documentsDashboard?: UserTableConfig;
  automaticSuggestions?: boolean;
  formulaPreview?: FormulaPreview;
  showConfidenceScore?: boolean;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hookState?: Record<string, any>;
};

export type AuthType = typeof SSO | typeof PASSWORD;

export type User = {
  authType: AuthType | undefined;
  dateJoined: string | undefined;
  deleted: boolean;
  email: string;
  firstName: string;
  groups: Array<string>;
  id: number;
  isActive: boolean;
  lastLogin: string | undefined;
  lastName: string;
  oidcId?: string | null;
  organization: string;
  passwordErrors: Record<string, string>;
  phoneNumber: string | null;
  pk: number;
  queues: Array<Url>;
  timestamp: string;
  uiSettings: UiSettings;
  url: string;
  username: string;
  emailVerified: boolean;
};

export type OrganizationUser = User & {
  queuesCount: number;
  role: GroupRole;
  workspacesCount: number;
};

export type UserList = Array<User>;
