import { StateCreator } from 'zustand';
import { ShowFormSchema } from '../hooks/intent.schema';

export type FormEvent = {
  form: ShowFormSchema;
  uuid: string;
};

export type FormState = {
  formEvent: FormEvent | undefined;
};

export type FormActions = {
  openForm: (event: FormEvent) => void;
  closeForm: () => void;
};

export type FormStoreType = FormState & FormActions;

export const formSlice: StateCreator<
  FormStoreType,
  [],
  [],
  FormStoreType
> = set => ({
  formEvent: undefined,
  openForm: formEvent => set({ formEvent }),
  closeForm: () => set({ formEvent: undefined }),
});
