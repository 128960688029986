import { endpoints, getIDFromUrl, Url } from '@rossum/api-client';
import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useApiClient } from '../../../lib/apiClient';
import { userHookStateSelector } from '../../../redux/modules/user/selectors';
import { useProcessIntent } from './useProcessIntent';

export const useInvokeHook = () => {
  const api = useApiClient();
  const location = useLocation();
  const { processIntent } = useProcessIntent();
  const hookState = useSelector(userHookStateSelector);

  return useMutation({
    onSuccess: processIntent,
    mutationFn: ({
      hook,
      payload,
    }: {
      hook: Url;
      payload?: Record<string, unknown> | undefined;
    }) =>
      api.request(
        endpoints.hooks.invoke(getIDFromUrl(hook), {
          ...payload,
          location,
          user_state: hookState,
        })
      ),
  });
};
