import { endpoints } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../../../lib/apiClient';
import { useInvokeHook } from './useInvokeHook';

export const useStartupHook = ({ enabled }: { enabled: boolean }) => {
  const api = useApiClient();
  const { mutate: invoke } = useInvokeHook();

  return useQuery({
    queryKey: ['startup_hook'],
    onSuccess: response => {
      const first = response.results[0];
      if (first) {
        invoke({
          hook: first.url,
          payload: {},
        });
      }
    },
    queryFn: () =>
      api.request(endpoints.hooks.list({ name: 'Start: ', active: true })),
    enabled,
  });
};
