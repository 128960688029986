import { withJsonFormsControlProps } from '@jsonforms/react';
import { QueueSelect } from '../../queues/select-queue/QueueSelect';

interface QueueControlProps {
  data: string;
  handleChange(path: string, value: string): void;
  path: string;
}

const QueueControl = ({ data, handleChange, path }: QueueControlProps) => (
  <QueueSelect
    value={data}
    onChange={(newValue: string | null) => {
      if (newValue) {
        handleChange(path, newValue);
      }
    }}
  />
);

export default withJsonFormsControlProps(QueueControl);
